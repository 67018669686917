
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.leftControls {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}

.search {
  width: 200px;
}

.select {
  width: 150px;
}

.datePicker {
  width: 120px;
}

.finalDatePicker {
  width: 190px;
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.controlsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
}

.rightControls {
  display: flex;
  gap: 16px;
}
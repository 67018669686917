
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.searchIcon {
    color: rgba(0, 0, 0, 0.45);
}
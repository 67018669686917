
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.leftControls,
.rightControls {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}

.datePicker {
  width: 120px;
}

.controlsContainer {
  display: flex;
  gap: 8px;
}

.multiSelect {
  width: 150px;
}
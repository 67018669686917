
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.container {
    width: 100%;
    padding: 24px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.rightControls {
    display: flex;
    gap: 16px;
}

.createPrepaymentButton {
    width: 160px;
}

.exportButton {
    background-color: white;
    border: 1px solid #ccc;
    color: #333;
    margin-right: 8px;
}

.tableBottom {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.form {
  padding-top: 8px;
}

.sendBtn {
  min-width: 120px;
}

.sendBtnContainer {
  text-align: right;
  margin-top: 16px;
  margin-bottom: 0;
}

.input {
  width: 100%;
}

.weekDaysRow {
  margin-bottom: 8px;
  align-items: start;
}

.monthDaysCard {
  margin-top: 24px;
}

.monthDaysRow {
  margin-bottom: 8px;
  align-items: start;
}

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.userBadge {
    display: flex;
    align-items: center;
    padding: $avatar-size / 2;
    height: $header-height;
    background-color: $background-color;
    border-radius: 0;
    border: none;

    &:hover {
        background-color: $hover-color;
        cursor: pointer;
    }

    .avatar {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: $avatar-border-radius;
        background-color: $avatar-bg-color;
    }

    .username {
        font-family: $primary-font;
        font-size: $font-size;
        font-weight: $username-font-weight;
        line-height: $username-line-height;
        color: $username-color;
    }

    .userNameContainer {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: $avatar-border-radius;
        background-color: $avatar-bg-color;
        display: flex;
        justify-content: center;
    }
}

.disabled {
    pointer-events: none;
}

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.wrapper {
  &::before {
    content: none;
    display: none;
  }
}

.cellContainer {
  position: relative;
  width: 100%;
  user-select: none;
}

.resizeHandle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  border-right: 2px solid #f0f0f0;

  &:hover {
    border-color: #66BB6A;

  }
}

.resizeHandleActive {
  border-color: #517ea5;
}
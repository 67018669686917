
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.root {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.loaderWrapper {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
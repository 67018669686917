
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.icon {
    color: $copy-icon-color;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        color: darken($copy-icon-color, 20%);
    }
}

.root {
    display: flex;
    align-items: center;
}
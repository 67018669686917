
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.container {
    width: 100%;
    padding: 24px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.rightControls {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.exportButton,
.createPrepaymentButton {
    width: 160px;
}

.detailTable {
    margin-top: 16px;
}

.detailIcon {
    cursor: pointer;
    font-size: 24px;
    color: $dnd-icon-color;
    text-align: center;

    &:hover {
        color: blue;
    }
}

.controlsContainer {
    display: flex;
    gap: 8px;
}

.tableBottom {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.container {
    width: 100%;
    padding: 24px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.tableBottom {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.identityContainer {
    width: 100%;
    padding: 24px 40px;
    box-sizing: border-box;
}

.column {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.titleColumn {
    color: #595959;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notFountText {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}